<template>
  <v-card>
    <v-card-title class="px-2">
      {{ $t('moment_drug_equipment') }} <v-spacer></v-spacer>
      <v-btn
        color="primary"
        :loading="exportLoading"
        :disabled="exportLoading"
        @click="exportExcel"
      >
        {{ $t('excel') }}
      </v-btn>
    </v-card-title>
    <Search2
      @onSendDate="searchLog"
    />
    <v-data-table
      :headers="columns"
      :items="dataTableList"
      :options.sync="options"
      :server-items-length="totalDataTableList"
      :footer-props="footer"
      :loading="loading"
      disable-sort
      :loading-text="$t('data_loading')"
      :no-data-text="$t('no_information')"
    >
      <template v-slot:[`item.id`]="{ index }">
        {{ segmentId + index + 1 }}
      </template>
      <template v-slot:[`item.drughistory_date`]="{ item }">
        <ConverseDate :date="item.drughistory_date" />
      </template>
      <template v-slot:[`item.drughistory_modify`]="{ item }">
        <ConversDateTime :datetime="item.drughistory_modify" />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import footerDataTables from '@/components/basicComponents/footerDataTables.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTablesEN.json'
import Search2 from './Search2.vue'
import { i18n } from '@/plugins/i18n'
import logs from '@/api/logs/logs'
import ConversDateTime from '@/components/basicComponents/ConversDateTime.vue'
import ConverseDate from '@/components/basicComponents/ConverseDate.vue'

export default {
  components: {
    Search2,
    ConversDateTime,
    ConverseDate,
  },
  setup() {
    const XLSX = require('xlsx')
    const loading = ref(false)
    const options = ref({})
    const payloadData = ref({})
    const dataTableList = ref([])
    const totalDataTableList = ref(0)
    const segmentId = ref(0)
    const columns = ref([
      {
        text: '#',
        align: 'start',
        value: 'id',
        width: 50,
      },

      {
        text: '',
        value: 'log_text',
      },
      {
        text: `${i18n.t('code')}`,
        value: 'drug_id',
        width: 150,
      },
      {
        text: `${i18n.t('name')}`,
        value: 'drug_name',
        width: 190,
      },
      {
        text: `${i18n.t('group')}`,
        value: 'drug_category_name',
        width: 150,
      },
      {
        text: `${i18n.t('lot_no')}`,
        value: 'drugorder_id',
        width: 150,
      },
      {
        text: `${i18n.t('lot')}`,
        value: 'druglot_name',
        width: 190,
      },
      {
        text: `${i18n.t('type')}`,
        value: 'drughistory_type',
        width: 150,
      },

      {
        text: `${i18n.t('amount')}`,
        value: 'drughistory_quantity',
        width: 150,
      },
      {
        text: `${i18n.t('unit')}`,
        value: 'drug_unit',
        width: 150,
      },
      {
        text: `${i18n.t('a_number')}`,
        value: 'id_text',
        width: 150,
      },
      {
        text: `${i18n.t('dispense')}`,
        value: 'name_text',
        width: 190,
      },
      {
        text: `${i18n.t('on_date')}`,
        value: 'drughistory_date',
        width: 190,
      },
      {
        text: `${i18n.t('record_date')}`,
        value: 'drughistory_modify',
        width: 190,
      },
    ])
    const footer = ref(i18n.locale == 'en' ? footerDataTablesEN : footerDataTables)
    const exportLoading = ref(false)
    const searchLog = payload => {
      payloadData.value = payload
      loading.value = true
      logs.logdrughistory({
        ...payload,
        active_page: options.value.page,
        per_page: options.value.itemsPerPage == -1 ? '0' : options.value.itemsPerPage,
      }).then(res => {
        const {
          data, count, segment,
        } = res
        dataTableList.value = data
        totalDataTableList.value = count
        segmentId.value = segment
        loading.value = false
      })
    }

    const exportExcel = () => {
      exportLoading.value = true
      logs.logdrughistory({
        ...payloadData.value,
        active_page: 1,
        per_page: '0',
      }).then(res => {
        const dataExport = res.data.map((item, i) => {
          delete item.log_text

          return { ...item, number: i + 1 }
        })
        const fileName = `${i18n.t('moment_drug_equipment')}.xlsx`

        //* เมื่อกดปุ่มจะทำการสร้างไฟล์ xcel ด้วย xlsx *//
        /*  รายงานวิเคราะห์รายการตรวจ */
        const Heading = [
          [`${i18n.t('moment_drug_equipment')}`],
          [
            '#',
            `${i18n.t('code')}`,
            `${i18n.t('name')}`,
            `${i18n.t('list')}`,
            `${i18n.t('group')}`,
            `${i18n.t('lot_no')}`,
            `${i18n.t('lot')}`,
            `${i18n.t('type')}`,
            `${i18n.t('amount')}`,
            `${i18n.t('unit')}`,
            `${i18n.t('a_number')}`,
            `${i18n.t('dispense')}`,
            `${i18n.t('on_date')}`,
            `${i18n.t('record_date')}`,
          ],
        ]
        const ws = XLSX.utils.aoa_to_sheet(Heading)
        XLSX.utils.sheet_add_json(ws, dataExport, {
          header: [
            'number',
            'drug_id',
            'drug_name',
            'log_text',
            'drug_category_name',
            'drugorder_id',
            'druglot_name',
            'drughistory_type',
            'drughistory_quantity',
            'drug_unit',
            'id_text',
            'name_text',
            'drughistory_date',
            'drughistory_modify',
          ],
          skipHeader: true,
          origin: -1,
        })
        const wb = XLSX.utils.book_new()
        const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 12 } }]
        const wscols = [
          { wch: 8 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
        ]
        ws['!merges'] = merge
        ws['!cols'] = wscols
        XLSX.utils.book_append_sheet(wb, ws, `${i18n.t('moment_drug_equipment')}`)

        /* พิมร์ files */
        XLSX.writeFile(wb, fileName)
        setTimeout(() => {
          exportLoading.value = false
        }, 1000)
      })
    }

    watch([options], () => {
      searchLog(payloadData.value)
    })

    return {
      XLSX,
      loading,
      options,
      dataTableList,
      totalDataTableList,
      columns,
      segmentId,
      searchLog,
      footer,
      exportLoading,
      exportExcel,

    }
  },
}
</script>
